import React from "react";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import RegionAssetsContainer from "/src/containers/region-assets";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import SetupContainer from "/src/setup-container";

const RegionAssetsPage = () => {
  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Region Assets`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <Router id="localized-router" basePath="/">
          <RegionAssetsContainer path="region-assets/:regionId" />
        </Router>
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default RegionAssetsPage;
