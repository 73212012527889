/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import AssetsListComponent from "./assets-list";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flex: 1,
  },
}));

const RegionAssetsComponent = ({ region, assets, loadingRegion, loadingAssets }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container direction="row">
        <Grid item xs={12} md={12} lg={12}>
          <Box position="relative">
            <AssetsListComponent
              region={region}
              assets={assets}
              loadingRegion={loadingRegion}
              loadingAssets={loadingAssets}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegionAssetsComponent;
