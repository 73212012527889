/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { getAssetSlug } from "../../utils";
import LoadingCard from "../card/loading-card";
import AssetCardComponent from "./asset-card";
import theme from "../../theme";
import Link from "../link";

const useStyles = makeStyles((theme) => ({
  breadcumbs: {
    paddingBottom: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      "& > nav > ol > li": {
        display: "none",
      },
      "& > nav > ol > li:nth-last-of-type(1)": {
        display: "flex",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& > nav > ol > li:nth-last-of-type(2)": {
        display: "flex",
      },
      "& > nav > ol > li:nth-last-of-type(3)": {
        display: "flex",
      },
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  title: {
    borderBottom: theme.borders[6],
    paddingBottom: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  emptySpace: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const AssetsListComponent = ({ region, assets, loadingRegion, loadingAssets }) => {
  const classes = useStyles(theme);

  return (
    <>
      <Box className={classes.breadcumbs}>
        <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
          <Link color="inherit" to="/">
            <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
              Home
            </Typography>
          </Link>
          <Typography variant="h6" style={{ color: theme.palette.black }}>
            {region.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box className={classes.title}>
        <Typography variant="h2">{region.name}</Typography>
      </Box>

      <Box mt={1} pb={4} display="flex" flexDirection="column" justifyContent="left" alignItems="center" width="100%">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignContent="start"
          flexWrap="wrap"
          width="100%"
        >
          {!assets.length && loadingAssets === "success" && (
            <Box className={classes.emptySpace}>
              <Typography variant="body1">
                <Trans>No se encontraron centros comerciales disponibles</Trans>
              </Typography>
            </Box>
          )}

          {assets.length && loadingAssets === "success" && loadingRegion === "success" ? (
            assets.map((s) => {
              return (
                <Box key={s.id} ml={1 / 2} mr={1 / 2} mt={1}>
                  <AssetCardComponent
                    id={s.id}
                    name={s.name}
                    image={s.thumb_url}
                    to={`/asset-spots/${getAssetSlug(s)}`}
                  />
                </Box>
              );
            })
          ) : (
            <Box mt={1} mb={2} display="flex" flexDirection={["column", "row"]} alignItems="center">
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </Box>
          )}
        </Box>

        {region && (
          <Box display="flex" flexDirection="column" pt={4} pl={2} pr={2} gridGap={4} width="100%">
            <Typography variant="h2" style={{ color: theme.palette.black }}>
              {region.name}
            </Typography>
            <Typography variant="body1">{region.seo}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AssetsListComponent;
